<template>
  <v-container fluid>
    <v-row dense>
      
        <v-btn v-if="editing && isLoggedIn" dark color="black" class="mb-2 op-btn-upload" text plain @click="showNewPlanDialog = true">
          <v-icon color="black">
          mdi-plus
          </v-icon>
        </v-btn>

        <v-col v-for="item in value" :key="item.id">
        <v-card flat>

          <router-link
            :to="{
              name: 'PlanView',
              params: { planid: item.id },
            }"
          >
            <v-img contain :src="thumbnailSource(item)" height="150px">
              <v-card-title v-text="item.title"></v-card-title>
            </v-img>
          </router-link>

          <div class="text-center d-flex justify-center">
            <v-card-actions v-if="editing && isLoggedIn && item.id">
              <v-spacer></v-spacer>
              <v-btn icon v-if="item.id">
                <v-icon small color="black" @click="editItem(item)">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="black" @click="deleteItem(item)">mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="justify-center">Are you sure you want to permanently delete this plan?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="op-card-actions" text @click="closeDelete">Cancel</v-btn>
            <v-btn class="op-card-actions" color="red" text @click="deleteItemConfirm">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <DialogNewPlan v-model="showNewPlanDialog" v-on:addNewItem="addNewItem" ></DialogNewPlan>

  </v-container>

</template>

<script>
import DialogNewPlan from "@/components/DialogNewPlan.vue";
//import Plan from "@/models/Plan.js";

export default {
  components:{
    DialogNewPlan,
  },
  props: {
    value: Array,
    editing: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
        type: Boolean,
        default: false
    },
  },

  data: () => ({
    dialog: false,
    dialogDelete: false,
    showNewPlanDialog: false,
    headers: [
      { text: "Thumbnail", value: "thumbnail" },
      { text: "Id", value: "id" },
      { text: "Actions", value: "action", sortable: false, align: "right" },
    ],
    editedIndex: -1,
    editedItem: {
      thumbnail: 0,
      id: 0,
    },

    defaultItem: {
      thumbnail: 0,
      id: 0,
    },

  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    thumbnailSource() {
      return item => item.thumbnail_path ? item.thumbnail_path : `${item.image_data}`;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    addNewItem(data) {
      // code to add new plan data to the project
      //data.image_data = null; // remove this when go to production to store actual image
      this.editedItem = Object.assign({}, data);
      this.save();
    },
    printItem(item){
      console.log("item");
      console.log(item);
    },
    initialize() {},
    editItem(item) {
      this.$router.push({ name: "PlanView", params: { planid: item.id }, query: { editing: true } });
    },
    deleteItem(item) {
      this.editedIndex = this.value.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$emit('planIdForDeleting', this.value[this.editedIndex].id);
      this.value.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.value[this.editedIndex], this.editedItem);
      } else {
        this.value.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
