<template>
  <v-dialog hide-overlay v-model="show" persistent max-width="500px" min-width="360px" max-height="500px"
    min-height="360px" @click:outside="show = false">
    <template>
      <div class="cell cell-map">
        <v-btn icon class="op-cell-close">
          <v-icon color="black" @click="show = false">mdi-close-box</v-icon>
        </v-btn>
        <MapContainer :key="mapKey" :geojson="geojson" v-on:select="selected = $event"></MapContainer>
      </div>
    </template>
  </v-dialog>
</template>

<script>

import MapContainer from './MapContainer'

export default {
  components: {
    MapContainer,
  },
  props: {
    mapKey: Number,
    value: Boolean,
    geolocation: {
      type: Object,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.updateGeojson();
  },
  watch: {
    geolocation: {
      handler() {
        this.updateGeojson();
      },
      deep: true,
    },
  },
  methods: {
    updateGeojson() {
      if (this.geolocation && this.geolocation.longitude && this.geolocation.latitude) {
        this.geojson.geometry.coordinates = [this.geolocation.longitude, this.geolocation.latitude].map(coord => parseFloat(coord));
      }
    },
  },
  data: () => ({
    dialog: true,
    valid: true,
    selected: undefined,
    geojson: {
      type: 'Feature',
      properties: {
        name: 'default object',
        quality: 'top'
      },
      geometry: {
        type: 'Point',
        coordinates: [
          null,
          null,
        ]
      }
    },
  }),
};
</script>
