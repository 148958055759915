<template>
    <v-card fluid flat>
      <v-toolbar flat dense class='op-breadcrumbs'>
        <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      </v-toolbar>

      <v-row no-gutters v-if="loading == false" class='op-project-toolbar'>
        <v-col cols="12" sm="12" md="12">
        <ProjectToolbar v-on:editProject="editProject" v-on:discardAndReload="discardAndReload" v-on:closeSave="closeSave" :project="project" :nameErrorMessage="nameErrorMessage" :editing="editing" :isLoggedIn="isLoggedIn" :userHasId="userHasId" :userHasRole="userHasRole"/>
        </v-col>
      </v-row>
      
      <v-row no-gutters v-if="loading == false" class='op-project-details'>
        <v-col cols="12" sm="3" md="3">
        <ProjectInfo :project="project" :editing="editing" :isLoggedIn="isLoggedIn"/>
        </v-col>
        <v-col cols="12" sm="6" md="7">
          <v-card flat>
            <PlansOfProjectCarousel :plans="[]"/>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3" md="2" class="d-none d-sm-block">
        <PlansOfProjectList v-model="project.plans" :editing="editing" :isLoggedIn="isLoggedIn"/>
        </v-col>
      </v-row>
    </v-card>
</template>

<script>
// @ is an alias to /src
import ProjectInfo from "@/components/ProjectInfo.vue";
import PlansOfProjectCarousel from "@/components/PlansOfProjectCarousel.vue";
import PlansOfProjectList from "@/components/PlansOfProjectList.vue";
import ProjectToolbar from "@/components/ProjectToolbar.vue";
import Project from "@/models/Project.js";
import { saveProject } from "@/api/project";
import { checkedLoginStatus } from "@/api/auth";
import { removeEmpty } from '@/utils.js';

export default {
  name: "NewProjectView",
  components: {
    ProjectInfo,
    PlansOfProjectCarousel,
    PlansOfProjectList,
    ProjectToolbar,
  },
  data() {
    return {
      loading: true,
      project: null,
      //router: this.$route.params.projectName,
      editing: true,
      pseudoLogin: false,
      nameErrorMessage: null,
    };
  },
  methods: {
    async checkLoginStatus() {
      const response = await checkedLoginStatus();
        if (response.succeeded) {
          console.log(response);
        } else {console.log(response)}
    },
    createNewProjectData() {
      this.project = new Project();
      //this.project.name = this.router;
      this.loading = false;
    },
    closeSave() {
      console.log(JSON.stringify(this.project));
      removeEmpty(this.project);
      this.tryToAddNewProject();
    },
    editProject(){
      this.editing = true;
    },
    discardAndReload(){
      /* reload page to discard changes */
      this.createNewProjectData();
        this.editing = false;
    },
    async tryToAddNewProject() {
      //if (this.$refs.registerForm.validate()) {
        // submit form to server/API here...
        const response = await saveProject(this.project);
        if (response.succeeded) {
          //this.$store.commit("login");
          this.projectRoute(response);
          this.show = false;
        } else {
          this.nameErrorMessage = response.error;
        }
    },
    projectRoute(value) {
        this.$router.push({
          name: 'ProjectView',
          params: {
            projectid: value.project_id,
          }
        });
    },
  },
  created() {
    this.createNewProjectData();
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
      /* Temporary Variable pseudoLogin for shortcut login */
      //return this.pseudoLogin;
    },
    userHasRole: function () {
      return this.$store.getters.userHasRole;
    },
    userHasId: function () {
      return this.$store.getters.userHasId;
    },
    breadcrumbs() {
      if (this.loading == false) {
        return [
          {
            text: "Projects",
            disabled: false,
            to: {
              name: "ProjectsView",
            },
          },
          {
            text: "New Project ",
            disabled: true,
            to: {
              name: "NewProjectView",
            },
          },
        ];
      } else {
        return [];
      }
    },
  },
};
</script>
