<template>
  <v-card fluid flat>
    <v-toolbar flat dense class='op-breadcrumbs'>
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
    </v-toolbar>

    <v-row no-gutters v-if="loading == false" class='op-project-toolbar'>
      <v-col cols="12" sm="12" md="12">
        <ProjectToolbar v-on:editProject="editProject" v-on:discardAndReload="discardAndReload"
          v-on:closeSave="closeSave" :project="project" :nameErrorMessage="nameErrorMessage" :editing="editing" :isLoggedIn="isLoggedIn"
          :userHasId="userHasId" :userHasRole="userHasRole" />
      </v-col>
    </v-row>

    <v-row no-gutters v-if="loading == false" class='op-project-details'>
      <v-col cols="12" sm="3" md="3">
        <ProjectInfo :project="project" :editing="editing" :isLoggedIn="isLoggedIn" />
      </v-col>
      <v-col cols="12" sm="6" md="7">
        <v-card flat>
          <PlansOfProjectCarousel :plans="project.plans" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" md="2" class="d-none d-sm-block">
        <PlansOfProjectList v-model="project.plans" v-on:planIdForDeleting="planIdForDeleting" :editing="editing" :isLoggedIn="isLoggedIn" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// @ is an alias to /src
import ProjectInfo from "@/components/ProjectInfo.vue";
import PlansOfProjectCarousel from "@/components/PlansOfProjectCarousel.vue";
import PlansOfProjectList from "@/components/PlansOfProjectList.vue";
import ProjectToolbar from "@/components/ProjectToolbar.vue";
import { fetchProject } from "@/api/project";
import { removeEmpty } from '@/utils.js';
import { saveProject } from "@/api/project";
import { deletePlan } from "@/api/plan";

export default {
  name: "ProjectView",
  components: {
    ProjectInfo,
    PlansOfProjectCarousel,
    PlansOfProjectList,
    ProjectToolbar,
  },
  data() {
    return {
      loading: true,
      project: null,
      router: this.$route.params,
      editing: false,
      pseudoLogin: false,
      planDeleteIds: [],
      nameErrorMessage: null,
    };
  },
  methods: {
    async readDataFromAPI() {
      this.project = await fetchProject(this.router.projectid);
      this.loading = false;
    },
    editProject() {
      this.editing = true;
    },
    discardAndReload() {
      /* reload page to discard changes */
      this.readDataFromAPI();
      this.editing = false;
      //this.$router.go();
    },
    async closeSave() {
      this.loading = true;
      if (this.planDeleteIds) {
        await this.TryToDeletePlans();
      }
      removeEmpty(this.project);
      this.prepareObjectAndSave();
      
      await this.tryToSaveProject();
      this.loading = false;
    },
    prepareObjectAndSave() {
      if ('plans' in this.project && Array.isArray(this.project['plans'])) {
        // 'plans' key doesn't exist or is not a list
        const plansList = this.project['plans'];
        for (let i = plansList.length - 1; i >= 0; i--) {
          const plan = plansList[i];
          if ('id' in plan) {
          // 'id' key exists, remove the plan from the list so that we only save newly added ones
          plansList.splice(i, 1);
          }
        }
      }
    },
    async tryToSaveProject() {
      //if (this.$refs.registerForm.validate()) {
      // submit form to server/API here...
      const response = await saveProject(this.project);
      if (response.succeeded) {
        this.show = false;
        this.reloadProjectView();
      } else {
          this.nameErrorMessage = response.error;
        }
    },
    async TryToDeletePlans() {
      for (let i = 0; i < this.planDeleteIds.length; i++) {
        const data = { plan_id: this.planDeleteIds[i] };
        const response = await deletePlan(data);
        if (response.succeeded) {
          console.log(response);
        }
      }
    },
    planIdForDeleting(id) {
      this.planDeleteIds.push(id);
    },
    reloadProjectView() {
      this.$router.go();
    },
  },

  created() {
    this.readDataFromAPI();
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
      /* Temporary Variable pseudoLogin for shortcut login */
      //return this.pseudoLogin;
    },
    userHasRole: function () {
      return this.$store.getters.userHasRole;
    },
    userHasId: function () {
      return this.$store.getters.userHasId;
    },
    breadcrumbs() {
      if (this.loading == false) {
        return [
          {
            text: "Projects",
            disabled: false,
            to: {
              name: "ProjectsView",
              query: { type: "browse" },
            },
          },
          {
            text: "Project " + this.project.id,
            disabled: true,
            to: {
              name: "ProjectView",
              params: { projectid: this.project.id },
            },
          },
        ];
      } else {
        return [];
      }
    },
  },
};
</script>
