<template>

  <v-dialog v-model="show" persistent max-width="600px" min-width="360px" @click:outside="show = false">
    <v-card class="px-4">
      <v-card-title>
        Add New Plan to Project
      </v-card-title>
      <v-card-text>
        <v-form ref="registerForm" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-file-input show-size accept="image/png, image/jpeg, image/bmp" placeholder="Upload an image"
                prepend-icon="mdi-camera" label="Image" @change="selectFile" @click:clear="clearFile"
                :rules="[rules.requiredFile]" :key="fileInputKey"></v-file-input>
              <v-img class='op-dialogue-upload-image' style="" :src="previewImage" alt="" />
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="floor" label="Plan Level" type="number"
                :rules="[rules.requiredFloor, rules.integerRule]"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="op-card-actions" text @click="cancel">
          Cancel
        </v-btn>
        <v-btn class="op-card-actions" text :disabled="!valid" @click="addNewPlanData" color='primary'>
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//import { signup } from "@/api/auth";

export default {
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    addNewPlanData() {
      let plan = Object.assign({}, this.newItem);
      plan.image_data = this.previewImage;
      plan.floor = this.floor;
      plan.width_mm = this.imageWidth,
      plan.height_mm = this.imageHeight,
      this.$emit('addNewItem', plan);
      this.show = false;
      this.resetForm()
    },
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
      this.readImage();
    },
    clearFile() {
      this.previewImage = null;
    },
    resetForm() {
      this.$refs.registerForm.reset();
      this.previewImage = null;
      this.fileInputKey++; // Increment the key to force re-rendering of v-file-input
    },
    cancel() {
      this.show = false;
      this.resetForm();
    },
    readImage() {
      if (this.currentFile) {
        let reader = new FileReader();
        reader.readAsDataURL(this.currentFile);
        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = () => {
            this.imageWidth = image.width;
            this.imageHeight = image.height;
          }
          this.previewImage = image.src;
          //console.log(this.previewImage);
          //this.image_data = e.target.result;
          // let image_data = this.previewImage;
          // this.$emit("loaded", { image_data });
        };
      }
    },
  },

  data: () => ({
    newItem: {
      image_data: null,
      thumbnail_path: null, //"https://open-plans.s3.eu-central-1.amazonaws.com/plan/4ddd69a0-fe3b-49f5-b254-f25bbb1927b3_thumbnail.jpeg", remove this when production
      image_path: null, //"https://open-plans.s3.eu-central-1.amazonaws.com/plan/4ddd69a0-fe3b-49f5-b254-f25bbb1927b3.jpeg", remove this when production
      width_mm: null,
      height_mm: null,
      mms_per_pixel: 10,
      floor: null,
      polygons: [],
    },
    dialog: true,
    valid: true,
    rules: {
      requiredFile: v => {
        if (v && v.size > 0) return true;
        return 'File is required.';
      },
      requiredFloor: v => {
        if (!isNaN(parseFloat(v))) return true;
        return 'Required.';
      },
      integerRule: v => {
        if (Number.isInteger(parseFloat(v))) return true; 
        return 'Round numbers only'
      },
    },
    currentFile: null,
    previewImage: null,
    fileInputKey: 0,
    floor: null,
    imageWidth: null,
    imageHeight: null,
  }),
};
</script>
