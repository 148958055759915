<template>
  <v-card class="op-project-info">
    <v-form>
      <v-container>
        <v-text-field 
          v-if="project.architects != null || (editing && isLoggedIn)" 
          label="Architect(s)"
          :value="project.architects" 
          @change="project.architects = $event" 
          hint="For example, Le Corbusier"
          :disabled="!editing || !isLoggedIn"
        ></v-text-field>

        <v-text-field 
          v-if="project.civil_engineers != null || (editing && isLoggedIn)" 
          label="Civil Engineer(s)"
          :value="project.civil_engineers" 
          @change="project.civil_engineers = $event"
          :disabled="!editing || !isLoggedIn"
        ></v-text-field>

        <v-text-field 
          v-if="project.clients != null || (editing && isLoggedIn)" 
          label="Client(s)"
          :value="project.clients" 
          @change="project.clients = $event"
          :disabled="!editing || !isLoggedIn"
        ></v-text-field>

        <v-layout class='op-project-info-cols'>
          <v-col 
            v-if="project.year_of_completion != null || (editing && isLoggedIn)" class='py-0'>
            <v-text-field 
              label="Year of completion" 
              :value="project.year_of_completion"
              @change="project.year_of_completion = $event" 
              type="number" 
              hint="For example, 1931"
              :disabled="!editing || !isLoggedIn"
            ></v-text-field>
          </v-col>
          
          <v-col 
            v-if="project.floors != null || (editing && isLoggedIn)" class='py-0'>
            <v-text-field 
              label="Floors" 
              :value="project.floors" 
              @change="project.floors = $event" 
              type="number"
              :disabled="!editing || !isLoggedIn"></v-text-field>
          </v-col>

          <v-col 
            v-if="project.floor_area != null || (editing && isLoggedIn)" class='py-0'>
              <v-text-field 
                label="Floor Area (sqm)" 
                :value="project.floor_area" 
                @change="project.floor_area = $event"
                type="number" 
                :disabled="!editing || !isLoggedIn"
              ></v-text-field>
          </v-col>

          <v-col 
            v-if="project.height != null || (editing && isLoggedIn)" class='py-0'>
              <v-text-field 
                label="Height (m)" 
                :value="project.height" 
                @change="project.height = $event" 
                type="number"
                :rules="integerRule"
                :disabled="!editing || !isLoggedIn"
              ></v-text-field>
          </v-col>
        </v-layout>

        <v-textarea 
          v-if="(editing && isLoggedIn)"
          auto-grow label="Description" 
          :value="project.description" @change="project.description = $event"
          hint="For example, Villa Savoye is a modernist villa in..." 
          :disabled="!editing || !isLoggedIn"
        ></v-textarea>

        <v-textarea 
          v-if="(project.description != null && project.description != '' || project.source != null) && !editing"
          auto-grow label="Description" 
          :value="getDescriptionWithSource(project.description, project.source)" 
          @change="project.description = $event"
          hint="For example, Villa Savoye is a modernist villa in..." 
          :disabled="!editing || !isLoggedIn"
        ></v-textarea>

        <v-text-field 
          v-if="(editing && isLoggedIn)" 
          label="Source"
          :value="project.source" 
          @change="project.source = $event" 
          hint="e.g. ISBN : 9783038600428"
          :disabled="!editing || !isLoggedIn"
        ></v-text-field>

        <v-combobox 
          v-if="(project.tags && project.tags.length > 0) || (editing && isLoggedIn)" 
          item-color='black'
          v-model="project.tags" 
          :items="items" chips 
          label="Project Tags" multiple solo flat
          :disabled="!editing || !isLoggedIn">
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip v-bind="attrs" :input-value="selected" :close="editing && isLoggedIn" close-icon="mdi-close" small
              color="black" text-color="white" @click="select" @click:close="remove(item)">
              {{ item }}&nbsp;
            </v-chip>
          </template>
        </v-combobox>

        <v-text-field 
          v-if="(editing && isLoggedIn)" 
          label="Building location" 
          :value="geolocationString"
          @change="setGeolocation" 
          :rules="coordinateRules" 
          hint="Lat, Long for example: 47.409015, 8.505935"
          :disabled="!editing || !isLoggedIn"
        ></v-text-field>

        <v-btn 
          v-if="(project.geolocation && !editing)" 
          icon color="black" 
          @click="showMap()">
          <v-icon>mdi-map-marker</v-icon>
        </v-btn>

        <DialogMap :geolocation="project.geolocation" :mapKey="mapKey" v-model="showMapDialog"></DialogMap>

      </v-container>
    </v-form>

  </v-card>
</template>

<script>

import DialogMap from "./DialogMap";

export default {
  components: {
    DialogMap,
  },
  props: {
    project: {
      type: Object,
      // Object or array defaults must be returned from
      // a factory function
    },
    editing: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    geolocationString() {
      if (this.project.geolocation) {
        const { latitude, longitude } = this.project.geolocation;
        return `${latitude}, ${longitude}`;
      }
      return null;
    },
  },
  data() {
    return {
      //chips: this.project.tags,//['Concrete', 'Steel', 'Appartment', 'Office','Mix used'],
      items: ['Highrise', 'Apartment', 'Detached', 'Hotel', 'House', 'Residential', 'Commercial', 'Industrial', 'Office', 'Retail', 'Religious', 'Public', 'Private'],
      showMapDialog: false,
      mapKey: 0,
      integerRule: [
        (v) => !v || Number.isInteger(parseFloat(v)) || 'Round numbers only'
      ],
      coordinateRules: [
        (v) => !v || /^((-?|\+?)?\d+(\.\d+)?),\s*((-?|\+?)?\d+(\.\d+)?)$/.test(v) || "For example: 47.409015, 8.505935"
      ],
    }
  },
  methods: {
    setGeolocation(event) {
      if (event) {
        const [latitude, longitude] = event.split(',');
        this.project.geolocation = { latitude: Number(latitude), longitude: Number(longitude) };
      } else {
        this.project.geolocation = null;
      }
    },
    getDescriptionWithSource(description, source) {
      if (source != null) {
        if (description == null) {
          return `Obtained from: ${source}`;
        } else if (description.trim().endsWith(".")) {
          return `${description.trim()} Obtained from: ${source}`;
        } else {
          return `${description.trim()}. Obtained from: ${source}`;
        }
      }
    },
    remove(item) {
      this.project.tags.splice(this.project.tags.indexOf(item), 1)
      this.project.tags = [...this.project.tags]
    },
    showMap() {
      this.showMapDialog = true;
      this.$nextTick(() => {
        this.mapKey += 1;
      });
    },
  },
}
</script>
