<template>
  <v-card flat>
    <v-carousel v-if="plans.length>1"
    height="auto"
    cycle
    hide-delimiters
    show-arrows-on-hover>
      <v-carousel-item v-for="(plan, i) in plans" :key="i">
        <PlanCard :plan="plan" />
      </v-carousel-item>
    </v-carousel>
  <PlanCard v-else :plan="plans[0]" />
  </v-card>
</template>

<script>
import PlanCard from "@/components/PlanCard.vue";
export default {
  name: "ProjectPlans",
  props: {
    plans: {
      type: Array,
    },
  },
  components: {
    PlanCard,
  },
};
</script>
