<template>
  <div ref="map-root"
       style="width: 100%; height: 100%">
  </div>
</template>

<script>
  import View from 'ol/View'
  import Map from 'ol/Map'
  import TileLayer from 'ol/layer/Tile'
  import OSM from 'ol/source/OSM'
  import VectorLayer from 'ol/layer/Vector'
  import VectorSource from 'ol/source/Vector'
  import GeoJSON from 'ol/format/GeoJSON'
  // import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style'

  import 'ol/ol.css'

  export default {
    name: 'MapContainer',
    components: {},
    props: {
      geojson: Object
    },
    data: () => ({
      olMap: null,
      vectorLayer: null,
      selectedFeature: null,
    }),
    mounted() {
      this.olMap = new Map({
        target: this.$refs['map-root'],
        layers: [
          new TileLayer({
            source: new OSM(),
            zIndex: 0
          })
        ],
        view: new View({
          center: [0, 0],
          zoom: 2,
          constrainResolution: true
        })
      })

      // create and add the vector layer
      this.vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        zIndex: 1
      });
      this.olMap.addLayer(this.vectorLayer);

      // load the geojson data and add it to the vector layer
      this.updateSource(this.geojson)

    },
    watch: {
      geojson(value) {
        this.updateSource(value)
      },
    },
    methods: {
      updateSource(geojson) {
        const view = this.olMap.getView();
        const source = this.vectorLayer.getSource();

        const features = new GeoJSON({
          featureProjection: 'EPSG:3857',
        }).readFeatures(geojson); 
        
        source.clear();

        source.addFeatures(features);
        view.fit(
          source.getExtent(),
          {
            padding: [200, 200, 200, 200],
          }
        );
        view.setZoom(15);
      }
    }
  }
</script>
