<template>
  <v-card flat light>
    <v-card flat light>
      <router-link v-if="plan && plan.id" :to="{
      name: 'PlanView',
      params: { planid: plan.id },
    }">
      <v-img
        class="op-plan-card-image"
        contain
        height="400px"
        width="100%"
        :src="plan.thumbnail_path"
      ></v-img>
    </router-link>
    <v-img v-else class="op-plan-card-image" contain height="400px" width="100%" />
    </v-card>
    <v-card flat light>
      <v-row no-gutters justify="center" width="100%" height="3rem" class="op-plan-caption">{{planCaption}}</v-row>
    </v-card>
  </v-card>
</template>
<script>
// @ is an alias to /src
export default {
  name: "PlanCard",
  props: {
    plan: {
      type: Object,
      default: null,
    },
  },
  computed: {
    planCaption() {
      if (this.plan && this.plan.floor) {
        return "LEVEL " + this.plan.floor;
      }
      return "";
    },
  },
};
</script>
