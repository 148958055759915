export default class Project {
  constructor(name = null, project_id = null,  description = null, civil_engineers = null, architects = null, source = null, year_of_completion = null, clients = null, floors = null, height = null, floor_area = null, plans = [], tags = []) {
    this.id = project_id;
    this.name = name;
    this.description = description;
    this.civil_engineers = civil_engineers;
    this.architects = architects;
    this.source = source;
    this.year_of_completion = year_of_completion;
    this.clients = clients;
    this.floors = floors;
    this.height = height;
    this.floor_area = floor_area;
    this.plans = plans;
    this.tags = tags;
  }
}