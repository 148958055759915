<template>
  <v-card class="op-project-info op-project-info-title">

    <v-toolbar flat dense>

      <v-form ref="registerForm" v-model="valid" lazy-validation>
        <v-container v-if="project!=null">
          <v-text-field class="op-project-title"
          label="Project Name"
          :value="project.name"
          @change="project.name = $event"
          :rules="[rules.required]"
          hint="For example, Villa Savoye"
          :disabled="!editing || !userHasId"
          :solo="!editing || !userHasId"
          :flat="!editing || !userHasId"
          :error-messages="nameErrorMessage"
          ></v-text-field>
        </v-container>
      </v-form>

      <v-btn icon v-if="editing && (!project.created_by || userHasId === project.created_by || userHasRole === 'administrator') ">
        <v-icon color="black" @click="dialogSave=true"> mdi-content-save</v-icon>
      </v-btn>
      <v-btn icon v-if="editing && (!project.created_by || userHasId === project.created_by || userHasRole === 'administrator')">
        <v-icon color="black" @click="dialogExit=true"> mdi-window-close</v-icon>
      </v-btn>
      <v-btn icon v-if="!editing && (!project.created_by || userHasId === project.created_by || userHasRole === 'administrator') && !$isMobile()">
        <v-icon color="black" @click="editingEmit"> mdi-square-edit-outline</v-icon>
      </v-btn>

    </v-toolbar>

    <v-dialog hide-overlay v-model="dialogSave" max-width="500px">
      <v-card>
        <v-card-title>Save your changes to this project?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="op-card-actions" text @click="closeExit">Cancel</v-btn>
          <v-btn class="op-card-actions op-highlight" text @click="closeSaveEmit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogExit" max-width="500px">
      <v-card>
        <v-card-title>Save your changes before exiting?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="op-card-actions" color="red" text @click="discardAndReloadEmit">Discard changes</v-btn>
          <v-btn class="op-card-actions" text @click="closeExit">Cancel</v-btn>
          <v-btn class="op-card-actions op-highlight" text @click="closeSaveEmit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>

</template>


<script>
export default {
  props: {
    project: {
      type: Object,
      // Object or array defaults must be returned from
      // a factory function
    },
    editing: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    userHasRole: {
      type: String,
      default: '',
    },
    userHasId: {
      type: Number,
      default: 0,
    },
    nameErrorMessage: {
      type: String,
      default: null,
    }
  },
  methods: {
    closeSaveEmit() {
      this.dialogSave = false;
      this.dialogExit = false;
      this.$emit('closeSave');
    },
    discardAndReloadEmit() {
      this.dialogExit = false;
      this.$emit('discardAndReload');
    },
    closeExit() {
      this.dialogSave = false;
      this.dialogExit = false;
    },
    editingEmit(){
      this.$emit('editProject');
    },
    resetError() {
      this.nameErrorMessage = null;
    }
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialogSave: false,
    dialogExit: false,
    valid: false,
  }),
}
</script>
